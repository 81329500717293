.login-row .tip-box h2 {
  color: rgb(0, 150, 137);
}
.login-box .login-sign-box {
  background-image: linear-gradient(
    to right,
    rgb(9, 168, 81),
    rgb(93, 241, 229)
  );
  padding: 20px 30px;
  border-radius: 30px;
  width: 75%;
  height: auto;
}

.login-box .login-sign-box a {
  color: #fff;
}
.login-box .login-sign-box i {
  font-size: 25px;
}
.login-row .inp-1 {
  width: 50px;
  position: absolute;
}
.login-row,
.tip-box {
  margin-top: 7%;
}

@media only screen and (max-width: 400px) {
  .login-box .login-sign-box {
    background-image: linear-gradient(
      to right,
      rgb(9, 168, 81),
      rgb(93, 241, 229)
    );
    width: 95%;
  }
  .login-row.tip-box h2 {
    font-size: 23px;
  }
  .login-row .login-sign-box h3 {
    font-size: 20px;
  }
  .login-row,
  .tip-box {
    margin-top: 3%;
  }
}
/* ------------step five--- */
.otp {
  width: 40px;
}
.fa-user {
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 8px 10px;
}
.hover-me:hover {
  cursor: pointer;
}
