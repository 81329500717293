.bgme {
  background-image: linear-gradient(
    to right,
    rgb(9, 168, 81),
    rgb(93, 241, 229)
  );
}
.bgme-trans {
  background-image: linear-gradient(
    to right,
    rgba(9, 168, 81, 0.7),
    rgba(93, 241, 229, 0.8)
  );
  border-radius: 20px;
}
a.text-white {
  text-decoration: underline;
}
a.text-white:hover {
  text-decoration: none;
}

@media only screen and (max-width: 520px) {
  .fa-user {
    display: none;
  }
  .btn-light {
    margin-right: 30px;
  }
  .another-div {
    max-height: 245px;
    overflow-y: scroll;
    display: inline;
  }
  #smallText h4 {
    font-size: 20px;
  }
  .btn {
    padding: 0.2rem 0.3rem !important;
  }
  #step-second-icon {
    display: none;
  }
  #choose-time-box,
  #Specificheight {
    margin: 5px !important;
  }
}
#Specificheight {
  min-height: 210px;
}

#step-second-button-row .show-book-time {
  background-color: rgb(51, 185, 51);
  border-radius: 50px;
}
#choose-time-box,
#Specificheight {
  background-image: linear-gradient(#ffffffe5, #05eeee6c);
}
#step-second-icon {
  font-size: 40px;
  color: #fff;
}
#back-button button {
  color: rgb(9, 168, 81) !important;
}

@media (max-width: 600px) {
  #step-second-button-row {
    min-height: 200px;
  }
}
.loading_wrapper {
  position: relative;
}
.load_wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: var(--black);
}
.load_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #fb5f1b 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #fb5f1b);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
