#main-div-fluid {
  background-image: linear-gradient(#ffffff75, #ffffff9d),
    url(./assets/img/background/appointment-back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 150vh;
  /* border: 2px solid red; */
  /* opacity: 0.5; */
}

#main-div-fluid #logo {
  margin-top: 20px;
  text-align: center;
}
#main-div-fluid #logo img {
  width: 250px;
  height: auto;
}
#main-div-fluid .start-book {
  background-image: linear-gradient(
    to right,
    rgb(93, 241, 229),
    rgb(9, 168, 81)
  );
  padding: 30px 50px 30px 50px;
  border-radius: 30px;
  margin-top: 5vh;
  width: 80%;
  height: auto;
}

#main-div-fluid .start-book select,
input {
  outline: none;
  border: none;
  width: 100%;
  height: auto;
  color: rgb(87, 86, 86);
}
#main-div-fluid .start-book select {
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  width: 50%;
}
#main-div-fluid .start-book .step-first-icon {
  color: rgb(121, 120, 120);
  position: absolute;
  margin-top: 13px;
  margin-left: 10px;
}
/* -------------------------media query--------------- */

@media only screen and (max-width: 400px) {
  #main-div-fluid .start-book h2 {
    font-size: 24px;
  }
  #main-div-fluid .start-book {
    padding: 15px 5px !important;
    width: 100%;
  }
  #main-div-fluid #logo img {
    width: 200px;
    height: auto;
  }
  #main-div-fluid .start-book select {
    width: 100% !important;
  }
  #main-div-fluid #rounded-pill,
  #get-otp {
    padding: 3px 20px !important;
    padding-bottom: 7px !important;
    font-size: 22px;
    font-weight: bold;
    border: none;
  }
  #step-six-icon {
    display: none;
  }
}

@media only screen and (max-width: 769px) {
  #main-div-fluid .start-book select {
    width: 80%;
  }
}
@media only screen and (max-width: 1000px) {
  #main-div-fluid .start-book {
    padding: 10px 20px 10px 20px;
  }
}
.MuiAutocomplete-hasClearIcon {
  width: 100% !important;
  background-color: #fff !important;
  border: none !important;
  border-radius: 25px;
  outline: none !important;
}
.MuiAutocomplete-inputFocused:focus {
  border: none !important;
  outline: none !important;
}
.MuiInput-underline:before,
.MuiInput-underline::after {
  content: none !important;
  left: none !important;
  top: none !important;
  bottom: none !important;
  text-align: center;
}
.MuiFormControl-fullWidth {
  padding: 10px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input,
.MuiAutocomplete-inputRoot[class*="MuiInput-root"]
  .MuiAutocomplete-input:first-child {
  padding: 0 !important;
}

.training-videos {
  text-align: center;
  margin: 20px 0px;
}
.training-videos h4 {
  font-size: 28px;
  font-weight: 500;
  text-transform: capitalize;
}
.traning-videos-items {
  display: flex;
  justify-content: center;
}
.traning-videos-items div {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.traning-videos-items button {
  background: linear-gradient(to right, rgb(9, 168, 81), rgb(93, 241, 229));
  color: #fff;
  font-weight: 600;
  padding: 5px 10px;
  border: #28a745;
  margin-left: 30px;
}
@media (max-width: 700px) {
  .traning-videos-items {
    display: flex;
    flex-direction: row;
  }
  .traning-videos-items div {
    margin-top: 15px;
  }
  .traning-videos-items button {
    border: 2px solid #000;
    padding: 8px 8px;
  }
}
