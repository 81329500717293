.app-detail-box {
  background-color: rgba(255, 255, 255, 0.705);
  border-radius: 20px;
}
.app-detail-heading {
  background-image: linear-gradient(
    to right,
    rgb(9, 168, 81),
    rgb(93, 241, 229)
  );
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.app-detail-box li {
  list-style-type: none;
  display: flex;
  line-height: 400%;
}
.app-detail-box li span:first-child {
  font-weight: 600;
  font-size: 20px;
  color: rgb(9, 168, 81);
  width: 200px;
}
.app-detail-box li span {
  font-weight: 600;
  font-size: 20px;
  color: rgb(37, 37, 37);
}
.app-detail-box #down-pdf {
  background-image: linear-gradient(
    to right,
    rgb(9, 168, 81),
    rgb(93, 241, 229)
  );
  color: #fff;
}
.app-detail-box .app-detail-date-time {
  color: rgb(9, 168, 81);
}

@media only screen and (max-width: 770px) {
  .app-detail-box li span:first-child {
    font-size: 18px;
  }
  .app-detail-box li span {
    font-size: 18px;
  }
}
@media only screen and (max-width: 520px) {
  .app-detail-box li span:first-child {
    font-weight: 600;
    font-size: 16px;
    width: 120px;
  }
  .app-detail-box li span {
    font-weight: 600;
    font-size: 16px;
  }
  .app-detail-box .app-detail-date-time h4 {
    font-size: 18px;
  }
  .app-detail-box li {
    padding: 0;
    line-height: 2.5;
  }
}

@media only screen and (max-width: 400px) {
  .app-detail-box li span:first-child {
    font-weight: 400;
    font-size: 14px;
    width: 100px;
  }
  .app-detail-box li span {
    font-weight: 400;
    font-size: 15px;
  }
  .app-detail-box ul li {
    padding-left: 0px;
  }
  .app-detail-box .app-detail-date-time h4 {
    font-size: 14px;
  }
}
.displaymeonprint {
  display: none;
}
@media print {
  .displayNotmeOnPrint {
    display: none;
  }
  .displaymeonprint {
    display: block;
    page-break-after: always !important;
  }
  .displaymeonprint #table-head {
    background-color: #17a2b8;
  }
  #table-heading-data {
    font-size: 24px;
  }
}
.body-design th {
  padding-left: 100px;
  /* border-bottom: 1px solid rgb(199, 199, 199); */
}
.body-design td {
  /* border-bottom: 1px solid rgb(202, 202, 202); */
  padding: 15px 50px;
}
.bottom-design {
  position: absolute;
  bottom: 0;
}
.logout-btn {
  background-color: #f00;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  transition: 0.5s linear all;
}
.logout-btn:hover {
  background-color: #fff;
  color: #f00;
  border: 2px solid #f00;
}
.have-logo-cutis img {
  width: 50%;
  height: auto;
}

.mask-text-pdf {
  text-align: center;
}
.mask-text-pdf h5 {
  font-size: 25px;
}
