#add-patient i {
  position: absolute;
  margin-top: 15px;
  margin-left: 12px;
}
#add-patient select {
  outline: none;
  border: none;
  width: 100%;
  height: 50px;
}
#add-patient #step-six-icon {
  font-size: 40px;
  color: rgb(8, 161, 161);
  margin-left: -15px;
}
#add-patient select {
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}
.box-color {
  background-image: linear-gradient(
    to right,
    rgb(9, 168, 81),
    rgb(93, 241, 229)
  );
  border-radius: 30px;
  padding: 20px 0;
}
.box-color1 {
  border-radius: 30px;
  padding: 20px 0;
  box-shadow: -1px 3px 10px 1px rgba(142, 142, 142, 0.75);
}
#made-color {
  background-image: linear-gradient(to right, rgb(9, 168, 81), rgb(9, 168, 81));
}
#grad-color {
  /* background-image: linear-gradient(
          to right,
          rgb(93, 241, 229),
          rgb(9, 168, 81)
        ); */
  color: #fff;
  font-weight: 550;
}
