.backup-text {
  text-align: center;
  margin-top: 30vh;
}
.backup-text img {
  height: 90px;
  width: 260px;
  margin-bottom: 10px;
}
.backup-text h2 {
  color: red;
  font-size: 45px;
}
.backup-text p {
  font-size: 25px;
  margin-top: 20px;
}
.backup-text a {
  text-decoration: none;
  color: black;
}
.backup-text a:hover {
  text-decoration: none;
}
