#upper-strip {
  background-image: linear-gradient(
    to right,
    rgb(93, 241, 229),
    rgb(9, 168, 81)
  );
}
#upper-strip img {
  width: 140px;
}
