#main-div-fluid {
  background-image: linear-gradient(#ffffff67, #ffffff9d),
    url(./assets/img/background/appointment-back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* opacity: 0.5; */
}
#upper-strip {
  background-image: linear-gradient(
    to right,
    rgb(9, 168, 81),
    rgb(93, 241, 229)
  );
}

#upper-strip #step-third-icon {
  color: #fff;
  font-size: 40px;
  margin-right: 15px;
}
#upper-strip #back-button button {
  color: rgb(9, 168, 81);
}
/* -------------------------profile--------------- */
.dr-profile h2 {
  color: rgb(0, 150, 137);
}
.dr-profile #doc-detail h1 {
  overflow: hidden;
}

.dr-profile .half-circle {
  background-image: linear-gradient(
    to right,
    rgb(9, 168, 81),
    rgb(93, 241, 229)
  );
  padding: 60px 60px 60px 100px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  height: fit-content;
  width: fit-content;
  margin-left: -14px;
}

.dr-profile .doctor-pic-circle img {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  border: 8px solid #fff;
}
.dr-profile #doc-detail ul {
  list-style-type: none;
  line-height: 400%;
}
.dr-profile #doc-detail li {
  display: flex;
}
.dr-profile #doc-detail li span:first-child {
  font-weight: 600;
  font-size: 28px;
  color: #000;
  width: 300px;
}
.dr-profile #doc-detail li span {
  font-weight: 600;
  font-size: 24px;
  color: rgb(37, 37, 37);
}

/* -------------------------media query--------------- */
@media only screen and (max-width: 1035px) {
  .dr-profile .doctor-pic-circle img {
    width: 220px;
    height: 220px;
  }
  .dr-profile .half-circle {
    padding: 30px 30px 30px 70px;
  }
  #doc-detail h1 {
    margin-top: 10px;
    font-size: 26px;
  }
  .dr-profile #doc-detail li span:first-child {
    font-weight: 500;
    font-size: 20px;
    color: #000;
    width: 180px;
  }
  .dr-profile #doc-detail li span {
    font-weight: 500;
    font-size: 18px;
    color: rgb(37, 37, 37);
  }
}

@media only screen and (max-width: 520px) {
  .dr-profile .doctor-pic-circle img {
    width: 250px;
    height: 250px;
  }
  #step-third-icon {
    display: none;
  }
  .dr-profile .half-circle {
    padding: 30px 30px 30px 70px;
  }
  #doc-detail h1 {
    margin-top: 10px;
    font-size: 30px;
    text-align: center;
  }
  .dr-profile #doc-detail li span:first-child {
    font-weight: 500;
    font-size: 22px;
    color: #000;
    width: 180px;
  }
  .dr-profile #doc-detail li span {
    font-weight: 500;
    font-size: 18px;
    color: rgb(37, 37, 37);
  }
}

@media only screen and (max-width: 400px) {
  #upper-strip #doctor-detail h4 {
    font-size: 18px;
  }
  .dr-profile h2 {
    font-size: 26px;
  }
  .dr-profile .doctor-pic-circle img {
    width: 180px;
    height: 180px;
  }
  .dr-profile .half-circle {
    padding: 23px 23px 23px 50px;
  }
  #doc-detail h1 {
    font-size: 22px;
  }
  .dr-profile #doc-detail li span:first-child {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    width: 120px;
  }
  .dr-profile #doc-detail li span {
    font-weight: 400;
    font-size: 14px;
    color: rgb(37, 37, 37);
  }
}
.textWillTransform {
  text-transform: lowercase;
}
.textWillTransform::first-letter {
  text-transform: uppercase;
}
